import { Link } from "gatsby";
import React, { useContext } from "react";
import BreadCrumb from "../components/layout/breadcrumb";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import { LanguageContext } from "../context/language-context";
import t from "../resources/translations";

const PrivacyPolicy = ({ location }) => {
  const l = useContext(LanguageContext).locale;
  return (
    <Layout location={location}>
      <Seo
        title="Privacy policy"
        desc="La Privacy Policy per l'utilizzo del sito ufficiale di International Saws."
      />
      <BreadCrumb>
        <Link to="/">Home</Link>
        <p className="font-bold">{t.privacyTitolo[l]}</p>
      </BreadCrumb>
      <div className="my-4 max-w-prose mx-auto">
        <h1 className="md:is-h1 is-h1m text-white mb-4">
          {t.privacyTitolo[l]}
        </h1>
        <h2>Premessa</h2>
        <p>
          L'accesso, la consultazione, il prelevamento di informazioni offerte
          dalla International Saws sono assolutamente gratuiti.
          <br /> La International Saws si riserva la facoltà di modificare in
          qualsiasi momento e senza alcun preavviso le modalità di utilizzo di
          questo sito web.
        </p>
        <br />
        <h3>Diritti</h3>
        <p>
          La proprietà intellettuale del sito web della International Saws
          appartiene esclusivamente alla International Saws s.r.l..
          <br /> Tutti i diritti ad esso inerenti sono riservati. La
          denominazione, il marchio e qualsiasi altro segno distintivo
          appartenente alla International Saws, così come tutte le immagini ed i
          testi pubblicati in questo sito web, non possono essere duplicati,
          riprodotti e/o diffusi in alcun modo e con alcun mezzo senza
          l’autorizzazione scritta.
          <br /> Tutti i marchi commerciali, nomi di prodotto e/o nomi di
          Società citati e/o rappresentati in questo sito web appartengono
          esclusivamente ai rispettivi proprietari.
        </p>
        <br />
        <h3>Informazioni</h3>
        <p>
          La International Saws non è responsabile per eventuali errori,
          omissioni e/o inaccuratezze nelle informazioni contenute in questo
          sito web e si riserva il diritto di eseguire qualsiasi modifica e/o
          rettifica delle stesse senza alcun avvertimento di alcun genere.
          <br />
          La International Saws è libero di pubblicare tutte le informazioni di
          qualsiasi genere e forma, ivi ricompresi articoli e recensioni,
          fornite dagli utenti o da terzi, fatto salvo il caso in cui gli stessi
          autori o fornitori ne vietino espressamente la diffusione.
          <br />
          La International Saws non si ritiene responsabile per l’eventuale
          violazione del copyright spettante a terzi effettuata dall’autore di
          un qualsiasi programma per elaboratore pubblicato in questo sito.
          International Saws si impegna a rimuovere immediatamente dai suoi
          archivi elettronici il programma realizzato in violazione delle leggi
          sul diritto d’autore, non appena venga a conoscenza della violazione
          stessa.
        </p>
        <br />
        <h3>Utilizzo</h3>
        <p>
          L’utente accetta espressamente di utilizzare il sito di International
          Saws a suo proprio rischio.
          <br /> International Saws non dà alcuna garanzia sul corretto
          funzionamento di questo sito web e declina ogni responsabilità
          derivante dall’utilizzo dello stesso. <br /> L'utente si impegna ad
          utilizzare il sito web della International Saws solamente per scopi
          legali. <br /> All'utente è severamente vietato trasferire su
          International Saws materiale che violi diritti di terzi e/o materiale
          illegale, diffamatorio, offensivo, osceno, nonché qualsiasi
          informazione di qualsiasi genere che possa in qualche modo
          incoraggiare azioni criminali sanzionate dalla legge e/o implicare
          responsabilità civile. <br /> Non di meno all'utente è fatto divieto
          di trasferire su International Saws materiale protetto da diritto
          d'autore, marchio registrato o da qualsiasi altra forma di diritto di
          proprietà senza il permesso del rispettivo autore e/o proprietario.{" "}
          <br /> International Saws non potrà essere considerato responsabile
          per l'uso illecito di copyrights e altri diritti spettanti a terzi,
          fatto ad opera dell'utente. <br /> Solamente quest'ultimo sarà
          considerato responsabile per i danni provenienti dalla violazione di
          copyrights e/o altri diritti di proprietà spettanti a terzi. <br />{" "}
          Con il trasferimento diretto o indiretto di materiale e/o informazioni
          su International Saws l'utente concede, o garantisce che il
          proprietario del materiale e/o dell'informazione abbia concesso, a
          International Saws il diritto gratuito, perpetuo e non-esclusivo di
          pubblicare, riprodurre, tradurre e distribuire detto materiale e/o
          informazioni integralmente o parzialmente. <br /> International Saws
          si impegna a rimuovere materiale e/o informazioni illecite o illegali
          trasferite su questo sito web da utenti, non appena venga a conoscenza
          dell'illecito o dell'illegalità.
        </p>
        <br />
        <h3>Mail</h3>
        <p>
          Inserendo del materiale nei nostri servers, per esempio tramite e-mail
          o attraverso le pagine del sito, l'utente si impegna a:
        </p>
        <br />
        <ul>
          <li>
            controllare che il materiale non contiene alcun dato illegale o
            altrimenti non idoneo alla pubblicazione;
          </li>
          <li>
            verificare e rimuovere ogni virus o qualsiasi altro elemento
            contaminante o distruttivo prima di introdurre del materiale.
          </li>
        </ul>
        <br />
        <h3>Collegamenti</h3>
        <p>
          I siti accessibili attraverso i collegamenti ipertestuali presenti su
          questo sito web non sono sotto il controllo di International Saws.{" "}
          <br /> La International Saws non è responsabile per i contenuti dei
          siti collegati o dei collegamenti contenuti in un sito collegato o per
          i cambiamenti o gli aggiornamenti di tali siti. <br /> L'inclusione di
          qualsivoglia collegamento su questo sito web non implica assolutamente
          approvazione da parte di International Saws del sito collegato.
        </p>
        <br />
        <h3>Responsabilità</h3>
        <p>
          In nessun caso e per nessuna ragione International Saws può essere
          considerato responsabile per qualsiasi danno, sia esso diretto,
          indiretto, incidentale, consequenziale, ed in particolar modo per
          danni aventi ad oggetto perdita di dati o profitti e/o perdita d’uso,
          derivanti o in qualche modo riconducibili al sito web della
          International Saws e/o alle informazioni in esso contenute.
        </p>
        <br />
        <h1>Cookie</h1>
        <p>
          Il presente documento nasce allo scopo di rendere note le procedure
          seguite per la raccolta tramite i cookie e/o altre tecnologie di
          monitoraggio delle informazioni fornite dagli utenti quando visitano
          questo sito web.
          <br /> I visitatori del sito potranno disabilitare nel browser la
          funzionalità del tracciamento dei cookies, ma in alcuni casi questo
          potrà comportare malfunzionamenti nell’uso del nostro sito web.
        </p>
        <br />
        <h2>Informazioni generali sulle norme</h2>
        <p>
          L’art. 122 del D. Lgs. 196/2003 ha recepito nell’ordinamento giuridico
          italiano una Direttiva Europea che impone ai gestori dei siti web che
          utilizzano cookie o altre tecnologie di monitoraggio, l’obbligo di
          informare l’utente circa le tipologie di eventuali cookie utilizzati
          dal sito, e di richiedere il consenso preventivo dello stesso
          all’invio di tali cookie sull’apparecchio terminale dell’utente e/o
          dell’accesso alle informazioni in esso registrate.
          <br /> Le sole eccezioni alla richiesta di consenso sono costituite
          dai cookie strettamente necessari alla gestione del sito, ovvero per
          l’erogazione di servizi espressamente richiesti dall’utente.
        </p>
        <br />
        <h2>Informazioni generali sui cookie</h2>
        <p>
          Utilizziamo cookie in alcune aree del nostro sito. I cookies sono file
          di testo i quali immagazzinano informazioni sugli hard drive
          dell’utente o sul browser di navigazione, e consentono di controllare
          se l’utente ha già visitato in passato il sito web, nonché le pagine
          del sito più visitate, in quanto registrano quali pagine sono state
          visitate ed il tempo di permanenza sulle stesse. <br /> Per le
          modifiche delle preferenze sui cookie, data la diversità
          nell’impostazione dei diversi browser utilizzati per l’accesso ad
          Internet, è consigliabile cliccare sulla voce di menù “Help/Aiuto” del
          browser utilizzato per capire come fare. Di seguito, l’elenco dei link
          dei browser maggiormente utilizzati, nei quali si spiega come gestire
          o disabilitare i cookie:
        </p>
        <br />
        <ul>
          <li>
            Internet Explorer:{" "}
            <a href="http://windows.microsoft.com/it-IT/internet-explorer/delete-manage-cookies">
              http://windows.microsoft.com/it-IT/internet-explorer/delete-manage-cookies
            </a>
            ;
          </li>
          <li>
            Google Chrome:{" "}
            <a href="https://support.google.com/chrome/answer/95647">
              https://support.google.com/chrome/answer/95647
            </a>
            ;
          </li>
          <li>
            Mozilla Firefox:{" "}
            <a href="http://support.mozilla.org/it/kb/Gestione%20dei%20cookie">
              http://support.mozilla.org/it/kb/Gestione%20dei%20cookie
            </a>
            ;
          </li>
          <li>
            Opera:{" "}
            <a href="http://help.opera.com/Windows/10.00/it/cookies.html">
              http://help.opera.com/Windows/10.00/it/cookies.html
            </a>
            ;
          </li>
          <li>
            Safari:{" "}
            <a href="https://support.apple.com/kb/PH19255">
              https://support.apple.com/kb/PH19255
            </a>
            .
          </li>
        </ul>
        <br />
        <h2>Tipologie di cookie utilizzate</h2>
        <p>
          Le tipologie di cookie utilizzate in questo sito sono le seguenti:
        </p>
        <br />
        <ul>
          <li>
            “Cookie tecnici” di sessione per l’autenticazione (gestione sito
            web, aree riservate, accettazione dei cookies);
          </li>
          <li>“Cookie di terze parti” per il monitoring (Google Analytics);</li>
          {/* <li>“Cookie di terzi parti” rappresentati dai “Social Plugin”;</li>
        <li>“Cookie di terze parti” per la visualizzazione di video embedded (Youtube).</li> */}
        </ul>
        <br />
        <h2>I cookie tecnici</h2>
        <p>
          L’uso dei cookie tecnici, ossia necessari alla trasmissione di
          comunicazioni su rete di comunicazione elettronica, ovvero cookie
          strettamente necessari al fornitore per erogare il servizio richiesto
          dal cliente, consente la fruizione sicura ed efficiente del nostro
          sito. <br /> Potranno essere installati cookie di sessione al fine di
          consentire l’accesso e la permanenza nell’area riservata del portale
          come utente autenticato. <br /> I cookie tecnici sono essenziali per
          il corretto funzionamento del nostro dominio e sono utilizzati per
          permettere agli utenti la normale navigazione e la possibilità di
          usufruire dei servizi avanzati disponibili sul nostro sito web. I
          cookie tecnici utilizzati si distinguono in cookie di sessione, che
          vengono memorizzati esclusivamente per la durata della navigazione
          fino alla chiusura del browser, e cookie persistenti che vengono
          salvati nella memoria del dispositivo dell’utente fino alla loro
          scadenza o cancellazione da parte dell'utente medesimo. Il nostro sito
          utilizza i seguenti cookie tecnici:
        </p>
        <br />
        <ul>
          <li>
            Cookie tecnici di navigazione o di sessione, utilizzati per gestire
            la normale navigazione e l'autenticazione dell'utente;
          </li>
          <li>
            Cookie tecnici funzionali, utilizzati per memorizzare
            personalizzazioni scelte dall'utente, quali, ad esempio, la lingua;
          </li>
          <li>
            Cookie tecnici Analytics, utilizzati per conoscere il modo in cui
            gli utenti utilizzano il nostro sito web così da poter valutare e
            migliorare il funzionamento.
          </li>
        </ul>
        <br />
        <h2>Cookie di terze parti per il monitoring (Google Analytics)</h2>
        <p>
          I cookie di terze parti per il monitoring possono essere disabilitati
          senza alcuna conseguenza sulla navigazione del sito web.
          <br /> Il presente sito, relativamente a tali cookie, si avvale del
          servizio Google Analytics fornito dalla società Google, Inc. (di
          seguito denominata “Google”) per la generazione di statistiche
          sull’utilizzo del portale web. Google Analytics utilizza cookie non di
          terze parti, i quali non memorizzano dati personali. Il luogo del
          trattamento dati è negli Stati Uniti d’America.
          <br /> Per l’informativa Google Analytics visitare il sito web{" "}
          <a href="http://www.google.com/intl/en/analytics/privacyoverview.html">
            http://www.google.com/intl/en/analytics/privacyoverview.html
          </a>
          .<br /> Per le norme sulla privacy di Google visitare il sito web{" "}
          <a href="https://www.google.it/intl/it/policies/privacy">
            https://www.google.it/intl/it/policies/privacy
          </a>
          .
        </p>
        <br />
        {/* <h2>Cookie di terze parti rappresentati dai social plugin</h2>
        <p>I cookie di terze parti rappresentati dai social plugin sono relativi a parti della pagina visitata generate dai siti dei social network (es. Facebook, Twitter, LinkedIn, Google+) integrate nella pagina del sito ospitante. L’utilizzo più comune dei social plugin è finalizzato alla condivisione dei contenuti sui social network.<br /> La presenza di detti plugin comporta la trasmissione di cookie da e verso tutti i siti gestiti da terze parti.<br /> Per maggiore trasparenza si riportano di seguito gli indirizzi web delle diverse informative dei social network, e delle modalità per la gestione dei cookie.</p>
        <br />
        <ul>
        <li>Facebook (informativa): <a href="https://www.facebook.com/help/cookies">https://www.facebook.com/help/cookies</a>;</li>
        <li>Facebook (configurazione): accedere al proprio account, nella sezione privacy;</li>
        <li>Twitter (informativa): <a href="https://support.twitter.com/articles/20170514">https://support.twitter.com/articles/20170514</a>;</li>
        <li>Twitter (configurazione): <a href="https://www.twitter.com/settings/security">https://www.twitter.com/settings/security</a>;</li>
        <li>LinkedIn (informativa): <a href="https://www.linkedin.com/legal/cookie-policy">https://www.linkedin.com/legal/cookie-policy</a>;</li>
        <li>LinkedIn (configurazione): <a href="https://www.linkedin.com/settings">https://www.linkedin.com/settings</a>;</li>
        <li>Google+ (informativa): <a href="https://www.google.it/intl/it/policies/technologies/cookies">https://www.google.it/intl/it/policies/technologies/cookies</a>;</li>
        <li>Google+ (configurazione): <a href="http://www.google.it/intl/it/policies/technologies/managing">http://www.google.it/intl/it/policies/technologies/managing</a>.</li>
        </ul><br />
        <h2>Cookie di terze parti per la visualizzazione di video embedded (Youtube)</h2>
        <p>I cookie di terze aprti per la visualizzazione di video embedded possono essere disabilitati senza alcuna conseguenza sulla navigazione del sito web.<br /> Il presente sito, relativamente a tali cookie, si avvale del servizio Youtube fornito dalla società Google, Inc. (di seguito denominata “Google”) per la visualizzazione di video. Youtube utilizza cookie non di terze parti, i quali non memorizzano dati personali. Il luogo del trattamento dati è negli Stati Uniti d’America.<br /> Per l’informativa Youtube consultare il sito web <a href="https://www.youtube.com/static?template=privacy_guidelines">https://www.youtube.com/static?template=privacy_guidelines</a>.<br /> Per le norme sulla privacy di Youtube visitare il sito web <a href="https://www.google.it/intl/policies/privacy">https://www.google.it/intl/policies/privacy</a>.</p>
        <br /> */}
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
